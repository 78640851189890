function getSznAdPopUp(configuration) {
  function logError(message) {
    // eslint-disable-next-line no-console
    console.error(message);
  }

  const sssp = window.sssp;

  if (!sssp || sssp.isStub) {
    logError('ssp.js nebyl načten');
    return;
  }

  function isValidConfiguration(config) {
    const requiredProperties = ['type', 'zoneId', 'breakPoint', 'fromFeed'];
    return requiredProperties.every((prop) => config.hasOwnProperty(prop));
  }

  function hasDuplicateZoneIds(configArray) {
    const uniqueZoneIds = new Set();

    for (const config of configArray) {
      if (!isValidConfiguration(config)) {
        logError(`Konfigurace neobsahuje všechny parametry: ${config}`);
        return true;
      }

      if (uniqueZoneIds.has(config.zoneId)) {
        logError(`Konfigurace obsahuje zóny s duplicitním ID: ${config}`);
        return true;
      }

      uniqueZoneIds.add(config.zoneId);
    }

    return false;
  }

  if (hasDuplicateZoneIds(configuration)) {
    return;
  }

  function createStyleElement(css) {
    const style = document.createElement('style');
    style.innerHTML = css;
    document.head.appendChild(style);
  }

  function appendStyles() {
    const css =
      '#seznam-pop-up-ad{ position: fixed; max-height:310px; height: auto; width: 100%; background: rgba(255, 255, 255, 1);bottom: 0; left: 0; right: 0;  text-align: center; padding: 0; z-index: 1001; transition: max-height 1s ease-out; }' +
      '#seznam-pop-up-ad-close{box-sizing: content-box; background: rgba(0, 0, 0, 1); margin-top: -31px; position: absolute; top: 0; right: 0; color: #fff; cursor: pointer; text-align: center; padding: 8px; height: 15px; font-family: Arial, Helvetica, sans-serif; font-size: 14px; line-height: 1; width: 140px; }' +
      '.seznam-pop-up-ad-hide{ max-height: 0 !important; padding: 0!important; margin: 0!important;}';

    createStyleElement(css);
  }

  function hideAd() {
    const popUpAd = document.getElementById('seznam-pop-up-ad');
    if (popUpAd) {
      popUpAd.classList.add('seznam-pop-up-ad-hide');
      const closeBtn = document.getElementById('seznam-pop-up-ad-close');
      if (closeBtn) {
        closeBtn.style.display = 'none';
      }
    }
  }

  function createAdDiv() {
    if (!document.getElementById('seznam-pop-up-ad')) {
      const div = document.createElement('div');
      div.id = 'seznam-pop-up-ad';
      document.body.appendChild(div);
    }
  }

  function addCloseBtnHandler() {
    const popUpAdCloseBtn = document.getElementById('seznam-pop-up-ad-close');
    if (popUpAdCloseBtn) {
      popUpAdCloseBtn.addEventListener('click', hideAd);
    }
  }

  function injectZones(zones) {
    const adDiv = document.getElementById('seznam-pop-up-ad');

    if (adDiv) {
      adDiv.innerHTML = zones
        .map(
          (zone) =>
            `<div id="seznam-pop-up-ad-close">zavřít reklamu</div>
           <div id="ssp-zone-${zone.zoneId}" style="margin: 0 auto;"></div>`
        )
        .join('');
    }

    addCloseBtnHandler();
  }

  function transformForCall(configurations) {
    const windowWidth = window.innerWidth;

    return configurations
      .filter((config) => {
        const isMobile = config.type === 'mobile';
        const isDesktop = config.type === 'desktop';

        if (isMobile) {
          return config.breakPoint >= windowWidth;
        }
        if (isDesktop) {
          return config.breakPoint <= windowWidth;
        }

        return false;
      })
      .filter((config, index, self) => {
        const isDuplicate = index === self.findIndex((c) => c.type === config.type && c.fromFeed === config.fromFeed);

        if (!isDuplicate) {
          logError(`Následující zóna se nevolá, protože má stejné nastavení jako jiná zóna: ${config}`);
        }

        return isDuplicate;
      })
      .filter((config) => (sssp.displaySeznamAds() ? config.fromFeed !== false : config.fromFeed !== true))
      .map((config) => ({
        onScrollElementId: config?.onScrollElementId,
        zoneId: config.zoneId,
        id: `ssp-zone-${config.zoneId}`,
        width: config.type === 'desktop' ? 970 : 500,
        height: config.type === 'desktop' ? 310 : 200,
        options: {
          infoCallback: (ad) => {
            if (['empty', 'error'].includes(ad.type)) {
              const popUpAd = document.getElementById('seznam-pop-up-ad');
              if (popUpAd) {
                popUpAd.style.display = 'none';
              }
            }
          },
          reloadCount: 0,
        },
      }));
  }

  function inject(transformedZones) {
    const transformedZone = { ...transformedZones[0] };
    delete transformedZone.onScrollElementId;

    injectZones(transformedZones);
    sssp.getAds(transformedZones[0]);
  }
  const scrollHandler = () => {
    appendStyles();
    const zonesToCall = configuration.filter((config) => {
      if (!config.onScrollElementId) {
        return true;
      }
      const elementTarget = document.getElementById(config.onScrollElementId);
      return elementTarget
        ? elementTarget && window.scrollY > elementTarget.offsetTop - window.innerHeight
        : logError(
            `Element po kterém se má zobrazit reklama nebyl nalezen. onScrollElementId: ${config.onScrollElementId} `
          );
    });

    if (zonesToCall?.length) {
      const transformedZones = transformForCall(zonesToCall);
      createAdDiv();
      if (transformedZones.length) {
        if (!transformedZones[0]?.onScrollElementId) {
          setTimeout(() => {
            inject(transformedZones);
          }, 8000);
        } else {
          inject(transformedZones);
        }

        if (transformedZones?.length) {
          window.removeEventListener('scroll', scrollHandler);
        }
      }
    }
  };

  window.addEventListener('scroll', scrollHandler);
}

window.getSznAdPopUp = getSznAdPopUp;
